<template>
  <q-layout class="layout">
    <SimpleHeader />
    <div class="container">
      <div class="page">

        <q-page-container class="page-container">
          <router-view />
        </q-page-container>
        <InfoBanner v-if="devMode"
                    :message="devMessage" />
      </div>
    </div>
  </q-layout>
</template>

<script>
// import api from 'api';
import { mapState } from 'vuex';
import SimpleHeader from '@/app/components/layout/header/simple-header.vue';
import InfoBanner from '@/app/components/layout/info-banner.vue';

export default {
  components: {
    SimpleHeader,
    InfoBanner,
  },
  data() {
    return {
      devMessage:
        "You're running Oasis in Dev Mode, don't forget to set the mode false (boolean property) in the store to switch on Production Mode",
    };
  },
  computed: {
    ...mapState('global', ['devMode']),
  },
  created() {
  },
};
</script>

<style lang="stylus" scoped>
.base-layout, .page-container
  overflow-x hidden
  min-height auto

.container
  display flex
  justify-content center
  align-items center
  margin auto
  padding-top 1%
  padding-bottom 1%
  .page
    width 1200px
    min-width 350px

</style>
