<template>
  <q-layout-header class="header">
    <div class="header__left"
         @wheel.prevent="">
      <router-link :to="oasisDefaultPage"
                   class="link--no-underline"
                   @click.native="clearCurrentBuilding">
        <OasisLogo />
      </router-link>
    </div>
  </q-layout-header>
</template>

<script>
import OasisLogo from '@/app/components/ui/logos/oasis-logo.vue';

export default {
  name: 'OHeader',
  components: {
    OasisLogo,
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    oasisDefaultPage() {
      return { name: this.user?.defaultPage };
    },
  },
  methods: {
    clearCurrentBuilding() {
      this.$store.commit('building/setSelectedBuilding', null);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.header
  .header__left, .header__center, .header__right
    display flex
    align-items center
    height 100%

.header
  position fixed
  display flex
  align-items center
  height 50px
  background-color $dark
  box-shadow none
  @media screen and (max-width: 500px)
    position fixed
    height 35px
  .header__left
    border-right 1px solid white
    background-color black
    a
      height 100%
    @media screen and (max-width: 500px)
      width 100px
      border-right none
  .header__center
    flex 2
  .header__right
    margin-left auto
  .header__info
    width 100%
    .header__info__building-name
      margin-left 12px
      padding-top 2px
      color white
      text-transform uppercase
    .floor-level__label
      position relative
      display flex
      justify-content center
      align-items center
      margin-right 12px
      margin-left 24px
      padding 2px 8px
      border-top-right-radius 10px
      border-bottom-right-radius 10px
      background-color $black
      color white
      font-weight 500
      font-size $fs-body
      &:after
        position absolute
        top 0
        bottom 0
        left -9px
        margin 0
        padding 0
        width 0px
        height 0px
        border-top 9.5px solid transparent
        border-right 9.5px solid $black
        border-bottom 9.5px solid transparent
        content ''

.header__link
  display flex
  justify-content center
  align-items center
  padding 0 65px
  height 100%
  border-right 1px solid white
  color white
  text-decoration none
  text-transform uppercase
  letter-spacing 1px
  font-weight 300
  font-size $fs-h2
  cursor pointer
  &:hover
    background-color darken($dark, 20%)
  &.router-link-active
    background-color white
    color black
    font-weight 500
</style>
